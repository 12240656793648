import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AlarmIcon from '@material-ui/icons/Alarm';
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title}>Service Information</h2>
          <h5 className={classes.description}>
           Fin24Taxi renders a trustworthy and secure taxi service in Finland, our expert drivers and quality vehicle fleet will assure a professional, reliable, and convenient transportation service. Expect our taxi service to take you to your chosen destination safely and reliably. We welcome advanced bookings with the most reasonable rates. 
          </h5>
          <h5 className={classes.description}>
          Whether you’re traveling alone or with friends, choices are always there. Book a request for a taxi, and let the best chauffeur in town serve you. Once your trip ends pay for your ride in the car, with cash or credit. 
          </h5>
          <h5 className={classes.description}>
          Book a ride with Fin24Taxi for your journey today, and grant us an opportunity to best serve you.
          </h5>
        
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Your Personal Ride"
              description="Accessible at your fingertips and serving you through your journey, and making it smooth, comfortable, and memorable."
              icon={EmojiTransportationIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="24/7 Service"
              description="Don't follow the clock while scheduling a taxi; We are available all time. One of the greatest gifts we can offer you is the value of your time."
              icon={AlarmIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Highly Secured"
              description="Your safety is the ultimate priority at Fin24Taxi, and we ensure securing it by implementing the industry's best safety guidelines."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
