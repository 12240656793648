export default {
    profile: 'Profile',
    home:'Home',
    about_us:'About Us',
    booking_history:'Booking History',
    logout:'Logout',
    notification_title:'Fin24taxi Notification',
    select_reason:'Select Cancellation Reason',
    no_cancel_reason:'No cancel reasons available.',
    ok:'Ok',
    cancel:'Cancel',
    select_country:'Choose a country',
    google_places_error:'Place ID to Location Error',
    not_valid_rider:'This login is a valid user but not Rider',
    new_booking_msg:'You Have A New Booking Request',
    no_driver:'No Driver Found',
    no_bookings:'No bookings available.',
    booking_cancelled:'Booking is cancelled. ID : ',
    no_cars:'No cars available.',
    no_route:'No Route Found.',
    firstname:'First Name',
    lastname:'Last Name',
    email:'Email',
    phone:'Phone Number',
    password:'Password',
    otp:'OTP',
    profile_updated:'Profile Updated.',
    proper_email:'Please enter email properly.',
    submit:'Submit',
    booking_title:'My Bookings',
    booking_id:'Booking ID',
    booking_date: 'Booking Date',
    car_type: 'Car Type',
    pickup_address :'Pickup Address',
    drop_address: 'Drop Address',
    booking_status: 'Booking Status',
    trip_cost:'Trip Cost',
    payment_status:'Payment Status',
    cancel_booking:'Cancel Booking',
    select_car:'Select Car Type',
    select_booking_type:'Select Booking Type',
    book_now: 'Book Now',
    book_later: 'Book Later',
    book: 'Book',
    select_proper:'Please select properly.',
    must_login:'Please Login for Booking',
    pickup_location:'Pickup Location',
    drop_location:'Drop Location',
    booking_success:'Booking successful. Booking Id : ',
    myaccount: 'My Account',
    login_signup: 'Login / Sign Up',
    login_success:'Login success. Need to complete registration.',
    login_validate_error:'Email or Password is not correct.',
    otp_validate_error:'OTP is not valid',
    reset_pass_msg:'Reset password link sent to email.',
    signin:'Sign In',
    login:'Login',
    register:'Register',
    get_otp:'Get OTP',
    verify_otp:'Verify OTP',
    complete_registration:'Complete Registration',
    forgot_pass_title:'Forgot Password',
    forgot_pass_description:'Fill up your email address and request. Link will be sent on your email inbox.',
    reset_password:'Reset Password',
    email_tab:'Email Login',
    phone_tab:'Phone Login',
    booking_date_time:'Booking Data Time',
    past_booking_error:'Ride Later is not avaialable for Past Datetime or within next 15 mins.',
    booking_taken:'Booking is taken. Driver will be assigned within 15 mins before your booking time. Your Booking ID is : ',
    settings_error: 'Unable to fetch settings.',
    estimate:'Fare Estimate',
    auth_error:'Auth Alert',
    email_verify_message:'Please verify your email. Email is already sent to your Inbox.',

    //custome
  assign_driver: 'Assign Driver',
  assign_booking: 'Assign Bookings',
  driver_type: 'Driver Type',
  distance: 'DISTANCE',
  time: 'TIME',
  rate: 'RATE',
  null: 'null',
  rider: 'Riders',
  pricing_slot: 'Pricing Slot',
  PRICING_SLOT_DATABASE: 'pricing_slot',
  no_driver_found: 'No Driver Found',
  convenience_fee: 'Convenience Fees',
  total: 'Total',
}