import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutUs(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 150,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
        <div className={classes.container}>
            <br/>
            <h2 className={classes.title} style={{fontFamily:"font-family: 'Poppins', sans-serif;"}}>About Us</h2>
            <p className={classes.description}>Fin24Taxi is a wholly-owned subsidiary of Finapps OY, an Information Technology company located in Vanta, Finland. </p>
            <p className={classes.description}>Fin24Taxi is a cloud-based Taxi bookings and fleet management software, which is a comprehensive and intuitive solution for running your transportation service business from anywhere, anytime.</p>
            <p className={classes.description}> Here at Fin24Taxi, we offer a 24-hour service to customers desiring to travel anywhere. We implement a safe, fixed-fee service, assuring that you arrive at your destination safely and for a price that can be affordably paid. All our drivers are fully examined and have accomplished all the knowledge tests. Every taxi in our fleet has a card machine, letting you pay promptly and smoothly on the spot.</p>
            <p className={classes.description}>Finapps OY is the parent firm and developer of the Fin24Taxi service. It specializes in developing web, cloud, and mobile applications - and helping organizations create and sustain digital innovation. Finapps effectively reduces development risk for its clients by using the agile development methodology, user-focused design, and standards-based code to get digital platforms to market quickly. The approach is fast, flexible, and collaborative.</p>
            <p className={classes.description}>At Finapps we're passionate about learning, developing ourselves professionally, and creating innovative digital products for clients.</p>
            <br/>
        </div>
        </div>

      <Footer />
    </div>
  );
}
