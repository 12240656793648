export const FirebaseConfig = {
  apiKey: "AIzaSyBfFUrBeDULErYfGnEko9aQmWs0ZfJFiB4",
    authDomain: "fin24taxi-59cf2.firebaseapp.com",
    databaseURL: "https://fin24taxi-59cf2-default-rtdb.firebaseio.com",
    projectId: "fin24taxi-59cf2",
    storageBucket: "fin24taxi-59cf2.appspot.com",
    messagingSenderId: "281855378986",
    appId: "1:281855378986:web:611c76b6dc3bba1f86a553",
    measurementId: "G-43CQ397K5G"
};

//export const google_map_key = 'AIzaSyAGG7Gq7_DEUwZ0kgAPOBbmm-WT8OUeFpw';
export const google_map_key = 'AIzaSyDTXiL__gHLdc7A10ERh_eB2im3kN_D_vc';