import React, { useState } from 'react';
import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem,
  makeStyles
}from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";

import sidelogo from '../assets/img/sidemenu_logo.png';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ExitIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import ListAltIcon from '@material-ui/icons/ListAlt';

import  languageJson  from "../config/language";
import {
  signOut
}  from "../actions/authactions";
import { ToggleOff } from '@material-ui/icons';


const useStyles = makeStyles({
  back:{
    '&:hover': {
      background: "#f6871e",
      color : 'white'
   },
   '&:active': {
    background: "#f6871e",
     color : 'white'
 },
 selected:{
   backgroundColor:"red",
 }
},
});

function AppMenu() {
  const dispatch = useDispatch();
  const location = useLocation()
  const classes = useStyles();
  const usesState = useState()
  const LogOut = () => {
    dispatch(signOut());
  };

    return (
    <div>
      <div style={{display: 'flex', justifyContent: 'center',backgroundColor:'white'}}>
        <img style={{marginTop:'20px',marginBottom:'20px',width:'120px',height:'120px'}} src={sidelogo} alt="Logo" />
      </div>
      <Divider/>
      <MenuList onClick={()=>console.log()}>
        <MenuItem component={Link} to="/" >
            <ListItemIcon style={{color:'#f6871e'}}>
              <HomeIcon />
            </ListItemIcon>
            <Typography variant="inherit">{languageJson.home}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/booking-history" selected={location.pathname === '/booking-history'}>
          <ListItemIcon style={{color:'#f6871e'}}>
            <ListAltIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.booking_history}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/my-profile" selected={location.pathname === '/my-profile'}>
          <ListItemIcon style={{color:'#f6871e'}}>
            <PersonOutlineIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.profile}</Typography>
        </MenuItem>
        <MenuItem onClick={LogOut}>
          <ListItemIcon style={{color:'#f6871e'}}>
            <ExitIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.logout}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppMenu;