/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/downloadStyle.js";
import triobanner from '../../assets/img/triobanner.jpg'

const useStyles = makeStyles(styles);

export default function SectionDownload() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer className={classes.textCenter} justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Mobile Apps available on App Stores</h2>
            <img className={classes.triobanner} src={triobanner} alt="Fin24taxi App Banner" />
            <h4 className={classes.description}>You can download and install the Fin24Taxi Rider app on Android and iPhone devices. Head to the App Store and find all our service apps there.</h4>
          </GridItem>
          <GridItem xs={12} sm={8} md={6}>
            <a href="https://firebasestorage.googleapis.com/v0/b/fin24taxi-59cf2.appspot.com/o/application%2Ffin24taxi-driver.apk?alt=media&token=b65f8dfb-4662-4d03-a0cb-e111753ffd34"><img src="https://firebasestorage.googleapis.com/v0/b/fin24taxi-59cf2.appspot.com/o/application%2Fdriver.png?alt=media&token=433c1f7b-a9d4-4c46-b0f6-10564d2af8aa" alt="Fin24Taxi Apple Store Link"/></a>
            <span style={{marginRight: '5px'}}></span>
            <a href="https://firebasestorage.googleapis.com/v0/b/fin24taxi-59cf2.appspot.com/o/application%2Ffin24taxi-rider.apk?alt=media&token=96f0c88b-a5b0-46fa-9fed-a5187e3ea568"><img src="https://firebasestorage.googleapis.com/v0/b/fin24taxi-59cf2.appspot.com/o/application%2Frider.png?alt=media&token=83608d35-0ea2-40ce-a259-f5def8f4472e" alt="Fin24Taxi Rider"/></a>
          </GridItem>
        </GridContainer>

      </div>
    </div>
  );
}
